.new-comment-area {
    padding-bottom: 10px;
    border-bottom: 1px solid gray;
}

.new-comment-area * {
    padding-top: 0px;
}


.user-details {
    width: '300px';
}

.user-details > * {
    display:inline-block; 
}

.profile-photo img{
    border-radius: 0;
    padding: -10px;
    
  }

.comment-info {
    padding: 10px;
}

.user-name {
    font-weight: bold;
}