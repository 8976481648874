.archDiagram {
    max-width: 60%;
    height: auto;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
  }
  
  .grid-item {
    width: 100%;
    cursor: pointer;
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    overflow-y: auto; /* Allow vertical scrolling */
    padding: 20px; /* Add some padding */
  }
  
  .modal-content {
    max-width: 80%;
    max-height: 800px; /* Maximum height for the image */
    width: auto;
    height: auto;
    margin: auto; /* Center the image in the modal */
  }
  
  .close-button {
    position: absolute;
    top: 20px;
    right: 30px;
    font-size: 30px;
    font-weight: bold;
    color: white;
    cursor: pointer;
  }
  